
import { CoreMenu } from '@core/types';

export const menuFinancial: CoreMenu[] = [
  {
    id: "settings",
    title: "Comercial",
    type: "collapsible",
    icon: "grid",
    children: [
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        url: "app/:company_id/reports",
      },
    ],
  },
];

export const menuFinancialLeal: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "pie-chart",
        url: "app/:company_id/reports",
      },
    ]
  }
];