import { environment } from "environments/environment";

export default class companyLogged {
  public static isLeal(): boolean {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    return environment.lealUserEmail.includes(currentUser?.email);
  }

  public static isVibecem(): boolean {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    return environment.vibeUserEmail.includes(currentUser?.emai);
  }
}
