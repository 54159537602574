import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'app/auth/models';

@Pipe({
  name: 'rolePipe'
})
export class RolesPipe implements PipeTransform {
  transform(value: string): any {
    return {
      [Role.Admin]: "Administrador",
      [Role.Manager]: "Gerente",
      [Role.Seller]: "Sales",
      [Role.Super_admin]: "Super Admin",
      [Role.Financial]: 'Financial',
      [Role.Marketing]: 'Marketing',
      [Role.Sales]: 'Sales'
    }[value] || '';
  }
}