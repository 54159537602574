<!-- Menu header -->
<div class="navbar-header" (mouseover)="expandMenuOnHover()">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="../../../../../assets/images/logo/Logo-vibe-yellow.png"
            alt="brand-logo"
            [width]="isCollapsed ? '40' : '200'"
            [height]="isCollapsed ? '25' : '45'"
          />
        </span>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="toggleSidebarCollapsible()"
      >
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="toggleSidebar()"
      >
        <i
          data-feather="x"
          class="font-medium-4 text-primary toggle-icon"
        ></i> </a
      ><!-- botao do menu -->
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Menu superadmin content -->
<div class="superadm-menu-content" *ngIf="role === 'super_admin'">
  <span
    class="badge badge-light-warning badge-superadm cursor-pointer"
    [routerLink]="'/app/' + companyId + '/superadm/access'"
  >
    Super Adm
  </span>
  <div>
    <div class="select-superadm" (click)="toggleSelect();">
      <span>Selecionar empresas</span>
      <div [ngClass]="{ 'rotate-180': !isOpenSelect }">
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="chevron-up">
            <path
              id="Path"
              d="M4.5 10L8.5 6L12.5 10"
              stroke="#FFBA02"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="select-superadm-options" *ngIf="isOpenSelect">
      <ul>
        <li
          *ngFor="let company of listCompanies; trackBy: trackById"
        >
          <a
            (click)="changeCompanyId(company?.id)"
            class="text-truncate"
            [class.active]="company?.id === companyId"
          >
            {{ company?.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Main menu -->
<div
  class="main-menu-content"
  [perfectScrollbar]
  (mouseover)="expandMenuOnHover()"
>
  <!--(scroll)="onSidebarScroll()"-->
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->
