import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'redirect-calendar',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class RedirectCalendarComponent implements OnInit {
    isSHowCalendar = null;

    constructor(
        private router: Router
    ) { }

    get companyId() {
        return localStorage.getItem("currentCompanyId");
    }

    ngOnInit(): void {
        localStorage.setItem("showCalendar", "true");

        while (!this.isSHowCalendar) {
            this.isSHowCalendar = JSON.parse(localStorage.getItem("showCalendar"));
            if (this.isSHowCalendar) this.router.navigate([`app/${this.companyId}/calendar`])
        }
    }
}