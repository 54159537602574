import { NgModule } from "@angular/core";

import { FilterPipe } from "@core/pipes/filter.pipe";

import { InitialsPipe } from "@core/pipes/initials.pipe";

import { SafePipe } from "@core/pipes/safe.pipe";
import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";
import { PhoneFormatPipe } from "./phone-format.pipe";
import { RolesPipe } from "./roles.pipe";

@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    PhoneFormatPipe,
    RolesPipe
  ],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, PhoneFormatPipe, RolesPipe],
})
export class CorePipesModule {}
