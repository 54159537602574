import { CoreMenu } from '@core/types';

export const menuSeller: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [
      {
        id: 'customer',
        title: 'Pacientes',
        type: 'item',
        icon: 'users',
        url: 'app/:company_id/customer'
      },
      {
        id: 'schedule',
        title: 'Agenda',
        type: 'item',
        icon: 'calendar',
        url: 'app/:company_id/calendar'
      }
    ]
  },
  {
    id: "settings",
    title: "Comercial",
    type: "collapsible",
    icon: "grid",
    children: [
      {
        id: "funnels",
        title: "Funil",
        type: "item",
        url: "app/:company_id/funnels",
      },
    ],
  },
];

export const menuSellerLeal: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [
      {
        id: 'customer',
        title: 'Clientes',
        type: 'item',
        icon: 'users',
        url: 'app/:company_id/customer'
      },
      {
        id: 'funnels',
        title: 'Funil',
        type: 'item',
        icon: 'filter',
        url: 'app/:company_id/funnels'
      },
      {
        id: 'calendar',
        title: 'Calendário',
        type: 'item',
        icon: 'calendar',
        url: 'app/:company_id/calendar'
      }
    ]
  }
];