import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "@fake-db/fake-db.service";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { ContextMenuModule } from "@ctrl/ngx-rightclick";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";

import { coreConfig } from "app/app-config";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { NgxMaskModule } from "ngx-mask";

import { NgApexchartsModule } from "ng-apexcharts";
import { CapitalizeFirstDirective } from "./capitalize-first.directive";
import { RedirectComponent } from "./views/redirect/redirect.component";
import { RedirectCalendarComponent } from "./views/login/redirect-calendar/redirect-calendar.component";
import { CadastroLinkModalComponent } from "./views/customer/importing-modal/cadastro-link-modal/cadastro-link-modal.component";
import { CreateNewLinkModalComponent } from "./views/customer/importing-modal/cadastro-link-modal/create-new-link-modal/create-new-link-modal.component";
import { EditLinkModalComponent } from "./views/customer/importing-modal/cadastro-link-modal/edit-link-modal/edit-link-modal.component";
import { DeleteLinkModalComponent } from "./views/customer/importing-modal/cadastro-link-modal/delete-link-modal/delete-link-modal.component";

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localePtExtra from "@angular/common/locales/extra/pt";

registerLocaleData(localePt, "ro", localePtExtra);

const appRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./views/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register-client-link/:companyId/:idLink",
    loadChildren: () =>
      import("./views/register-client-link/register-client-link.module").then(
        (m) => m.RegisterClientLinkModule
      ),
  },
  {
    path: "user/verify/:email",
    loadChildren: () =>
      import("./views/account-confirmed/account-confirmed.module").then(
        (m) => m.AccountConfirmedModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./views/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "reset-password/:email",
    loadChildren: () =>
      import("./views/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "app/:company_id",
    loadChildren: () =>
      import("./views/views.module").then((m) => m.ViewsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "company",
    loadChildren: () =>
      import("./views/companies/companies.module").then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: "redirect",
    component: RedirectComponent,
  },
  {
    path: "auth/google/callback",
    component: RedirectCalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [
    AppComponent,
    CapitalizeFirstDirective,
    CadastroLinkModalComponent,
    CreateNewLinkModalComponent,
    EditLinkModalComponent,
    DeleteLinkModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    NgApexchartsModule,
    ContentHeaderModule,
    NgxMaskModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: LOCALE_ID, useValue: "ro-PT"}]
})
export class AppModule {}
