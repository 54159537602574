import { CoreMenu } from '@core/types';

export const menuSeller: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [
      {
        id: 'customer',
        title: 'Clientes',
        type: 'item',
        icon: 'users',
        url: 'app/:company_id/customer'
      },
      {
        id: 'funnels',
        title: 'Funil',
        type: 'item',
        icon: 'filter',
        url: 'app/:company_id/funnels'
      },
      {
        id: 'calendar',
        title: 'Calendário',
        type: 'item',
        icon: 'calendar',
        url: 'app/:company_id/calendar'
      }
    ]
  }
];