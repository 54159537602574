import { CoreMenu } from "@core/types";
import companyLogged from "app/utils/company-logged";

export const menuAdmin: CoreMenu[] = [
  {
    id: "apps",
    type: "section",
    title: "Menu",
    icon: "package",
    children: [
      {
        id: "patients",
        title: "Pacientes",
        type: "item",
        icon: "users",
        url: "app/:company_id/customer",
      },
      {
        id: "mada",
        title: "Mada",
        type: "item",
        icon: "message-square",
        url: "app/:company_id/qr-mada",
        disabled: false,
        badge: {
          title: "Novidade",
          classes: "badge-light-warning badge-pill",
        },
      },
      {
        id: "schedule",
        title: "Agenda",
        type: "item",
        icon: "calendar",
        url: "app/:company_id/calendar",
      },
      {
        id: "send-messages",
        title: "Disparador de Mensagens",
        type: "item",
        icon: "mail",
        url: "app/:company_id/send-messages",
        hidden: !companyLogged.isLeal(),
      },
      {
        id: "leads",
        title: "Captação de leads",
        type: "item",
        icon: "crosshair",
        url: "app/:company_id/leads",
        hidden: !companyLogged.isLeal(),
      },
    ],
  },
  {
    id: "settings",
    title: "Comercial",
    type: "collapsible",
    icon: "grid",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "app/:company_id/dashboard",
      },
      {
        id: "funnels",
        title: "Funil",
        type: "item",
        url: "app/:company_id/funnels",
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        url: "app/:company_id/reports",
      },
    ],
  },
  {
    id: "settings",
    title: "Configurações",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "add-company",
        title: "Empresas",
        type: "item",
        url: "app/:company_id/settings/add-company",
      },
      {
        id: "employee",
        title: "Colaboradores",
        type: "item",
        url: "app/:company_id/settings/employee",
      },
    ],
  },
];

export const menuAdminLeal: CoreMenu[] = [
  {
    id: "apps",
    type: "section",
    title: "Menu",
    icon: "package",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: "grid",
        url: "app/:company_id/dashboard",
      },
      {
        id: "customer",
        title: "Clientes",
        type: "item",
        icon: "users",
        url: "app/:company_id/customer",
      },
      {
        id: "funnels",
        title: "Funil",
        type: "item",
        icon: "filter",
        url: "app/:company_id/funnels",
      },
      {
        id: "mada",
        title: "Mada",
        type: "item",
        icon: "message-square",
        url: "app/:company_id/qr-mada",
        disabled: false,
        badge: {
          title: "Novidade",
          classes: "badge-light-warning badge-pill",
        },
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "pie-chart",
        url: "app/:company_id/reports",
      },
      {
        id: "calendar",
        title: "Calendário",
        type: "item",
        icon: "calendar",
        url: "app/:company_id/calendar",
      },
      {
        id: "send-messages",
        title: "Disparador de Mensagens",
        type: "item",
        icon: "mail",
        url: "app/:company_id/send-messages",
        hidden: !companyLogged.isLeal(),
      },
      {
        id: "leads",
        title: "Captação de leads",
        type: "item",
        icon: "crosshair",
        url: "app/:company_id/leads",
        hidden: !companyLogged.isLeal(),
      },
    ],
  },
  {
    id: "settings",
    title: "Configurações",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "add-company",
        title: "Empresas",
        type: "item",
        url: "app/:company_id/settings/add-company",
      },
      {
        id: "employee",
        title: "Colaboradores",
        type: "item",
        url: "app/:company_id/settings/employee",
      },
    ],
  },
];