import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'redirect-calendar',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class RedirectCalendarComponent {
    isSHowCalendar = null;

    constructor(
        private router: Router
    ) { }

    get companyId() {
        return localStorage.getItem("currentCompanyId");
    }
}